<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3">
        <user-menu />
      </div>
      <div class="col-12 col-lg-9">
        <div class="row">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserMenu from '@/components/user/Menu';
export default {
  name: 'UserLayout',
  components: {
    UserMenu,
  },
};
</script>
